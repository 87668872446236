<template>
  <b-card-code
      title="Új hozzáadása"
  >
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>

          <b-col md="12" class="mb-3">
            <div class="mb-1">A képfeltöltéshez húzza rá, vagy kattintás után tallózza be a képet a lenti
              beviteli mező segítségével.
            </div>
            <b-row>
              <b-col sm="10">
                <b-form-file
                    v-model="formData.file"
                    placeholder="Válassz vagy húzd ide a fájlt"
                    drop-placeholder="A fájlt húzd ide ..."
                    accept="
                      image/svg+xml,
                      "
                />
              </b-col>
              <b-col sm="2">
                <b-button
                    v-if="formData.file"
                    variant="danger"
                    class="float-left mr-1"
                    @click.prevent="() => {formData.file = null}"
                >
                  <feather-icon
                      icon="Trash2Icon"
                      size="15"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-col>

          <!-- submit button -->
          <b-col class="mt-1">
            <b-button
                variant="primary"
                type="submit"
                class="float-right"
                :disabled="!formData.file"
                @click.prevent="submitForm"
            >
              Mentés
            </b-button>
            <cancel-and-g-o-t-o-previous-page class="mr-2"/>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormFile,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormFile,
    cancelAndGOTOPreviousPage,
  },
  data() {
    return {
      role: 'reservation',
      formData: {
        file: null,
      },
      required,
    }
  },
  created(){
    this.$store.dispatch('fetchReservationDashboard').then(response => {
      if(Boolean(response.data.closed) === false){
        this.$helpers.showErrorToast('Új térkép hozzáadására csak akkor nyílik lehetőség, ha a foglalás zárva van.');
        this.$router.push({name: 'reservation-maps'})
      }
    })
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const fd = new FormData();
          fd.append('file', this.formData.file)

          this.$store.dispatch('storeReservationMap', fd).then(response => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({ name: 'modify-reservation-map', params: { id: response.data.entity.id }})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else if(error.response.status === 500){
              this.$helpers.showErrorToast(error.response.data.message)
            }else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
